<template>
  <label class="input-copy" :for="name">
    <span v-if="label" class="label"> {{ label }}<span v-if="isRequired" class="required">*</span> </span>

    <span class="row">
      <input
        :id="name"
        ref="copyInput"
        class="field"
        :readonly="true"
        :value="value"
        :name="name"
        :required="isRequired"
        :placeholder="placeholder"
        @blur="emit('blur')"
        @input="emit('input')"
        @click="emit('input-click')"
      >

      <button class="btn-copy" :disabled="!value" @click.prevent="emit('qr-click')">
        <atomic-icon v-if="iconId" :id="iconId" />
      </button>
    </span>
  </label>
</template>

<script setup lang="ts">
  interface IProps {
    name: string;
    value?: string;
    label: string;
    placeholder?: string;
    isRequired?: boolean;
    iconId?: string
  }

  withDefaults(defineProps<IProps>(), {
    value: ' ',
    placeholder: '',
    isRequired: false,
    iconId: undefined
  });


  const emit = defineEmits(['blur', 'input', 'qr-click', 'input-click']);
</script>

<style src="~/assets/styles/components/form/input/copy.scss" lang="scss" />
